<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->
<template>
  <v-container>
    <v-card
      rounded
      elevation="3"
      class="pa-5"
    >
      <v-container fluid>
        <v-row justify="center">
          <v-col cols="auto">
            <v-icon
              :colorc="dataType"
              size="50"
            >
              {{ isSuccess ? 'mdi-check-circle' : 'error' }}
            </v-icon>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col
            cols="auto"
            class="result-container"
          >
            <h3
              class="result"
              v-html="message"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>
<script>
import { MetaInfoMixin } from '@mixins'

export default {
  name: 'ResultApi',
  mixins: [MetaInfoMixin],
  data: () => ({}),
  computed: {
    message () {
      return this.$route?.query?.message || this.$t('messages.error')
    },
    isSuccess () {
      return this.$route?.query?.r === 'success' && this.$route?.query?.message
    },
    dataType () {
      return this.isSuccess ? 'success' : 'error'
    }
  },
  beforeMount () {
    if (this.$route.query.locale) {
      this.AppLocale = this.$route.query.locale
    }
  }
}
</script>

<style>
.result a {
  color: var(--v-primary-base) !important;
}

.result-container {
  font-family: Arial, 'sans-serif' !important;
}
</style>
